document.addEventListener("DOMContentLoaded", function() {
    const jsConfetti = new JSConfetti();

    setTimeout(() => {
        jsConfetti.addConfetti();
    }, 2000);
    
    setTimeout(() => {
        window.location.href = 'principal.html';
    }, 5000);
    
});

const neonText = document.getElementById('neon-text');
const neonColors = ['#00ff00', '#ff00ff', '#00ffff', '#ff0000', '#ffff00', '#ff9900', '#9900ff'];
let currentColorIndex = 0;

function changeNeonColor() {
    currentColorIndex = (currentColorIndex + 1) % neonColors.length;
    const newColor = neonColors[currentColorIndex];
    neonText.style.color = newColor;
    neonText.style.textShadow = `0 0 10px ${newColor}, 0 0 20px ${newColor}, 0 0 30px ${newColor}, 0 0 40px ${newColor}, 0 0 50px ${newColor}, 0 0 60px ${newColor}, 0 0 70px ${newColor}`;
}

setInterval(changeNeonColor, 1000);
